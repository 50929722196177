const Contact = () => {
  return (
    <div className="min-h-screen flex flex-col lg:flex-row items-center mt-20 lg:mt-0 gap-y-10 mx-auto w-5/6"
         id="contact">
      <div className="flex flex-col text-white h-full lg:w-1/2">
        <h1 className="text-4xl lg:text-6xl font-bold text-[#38bdf8]">
          Contact
        </h1>
        <p className="text-xl mt-5">
          I am looking forward to working with you :)
        </p>
        <p className="text-xl mt-5">
          Contact me via email
          <span className="text-[#38bdf8] cursor-pointer tooltip tooltip-bottom"
                data-tip="Copy"
                onClick={() => navigator.clipboard.writeText("artur.onopriichuk@gmail.com")}>
                        &nbsp;artur.onopriichuk@gmail.com&nbsp;
                    </span>
          or by social networks listed below.
        </p>
        <div className="flex justify-between lg:justify-start lg:gap-10 mt-10">
          <a href="https://www.linkedin.com/in/artur-onopriichuk"
             target="_blank"
             className="tooltip"
             data-tip="LinkedIn">
            <div className="h-20 w-20 bg-[#38bdf8] flex justify-center items-center rounded-xl">
              <img src="images/linkedin.svg" className="w-10 h-10"/>
            </div>
          </a>
          <a href="https://djinni.co/q/195b19df03/"
             target="_blank"
             className="tooltip"
             data-tip="Djinni">
            <div className="h-20 w-20 bg-[#38bdf8] flex justify-center items-center rounded-xl">
              <img src="images/djinni.svg" className="w-10 h-10"/>
            </div>
          </a>
          <a href="https://t.me/art_i_i"
             target="_blank"
             className="tooltip"
             data-tip="Telegram">
            <div className="h-20 w-20 bg-[#38bdf8] flex justify-center items-center rounded-xl">
              <img src="images/telegram.svg" className="w-10 h-10"/>
            </div>
          </a>
        </div>
      </div>
      <div className="flex flex-col text-white h-full lg:w-1/2 items-center">
        <iframe src="https://giphy.com/embed/btbUGSHh3f6eBjbDfh" frameBorder="0"
                className="w-72 h-72" allowFullScreen>
        </iframe>
      </div>
    </div>
  );
};

export default Contact;