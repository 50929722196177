const Navbar = () => {
  return (
    <div className="absolute top-0 w-screen z-50">
      <div className="navbar glass text-white">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M4 6h16M4 12h8m-8 6h16"/>
              </svg>
            </label>
            <ul tabIndex={0}
                className="menu dropdown-content mt-3 p-2 shadow bg-black rounded-box w-52 text-xl font-bold">
              <li><a href="#about-me">About Me</a></li>
              <li><a href="#my-skills">My Skills</a></li>
              <li><a href="#projects">Projects</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          <a href="#home"
             className="hidden lg:block btn bg-transparent hover:bg-transparent border-none normal-case text-3xl pt-2">ARTUR
            ONOPRIICHUK</a>
        </div>
        <div className="navbar-end hidden lg:flex text-xl font-bold">
          <ul className="menu menu-horizontal p-0">
            <li><a href="#about-me" className="bg-transparent hover:text-[#38bdf8]">About Me</a></li>
            <li><a href="#my-skills" className="bg-transparent hover:text-[#38bdf8]">My Skills</a></li>
            <li><a href="#projects" className="bg-transparent hover:text-[#38bdf8]">Projects</a></li>
            <li><a href="#contact" className="bg-transparent hover:text-[#38bdf8]">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;