// @ts-ignore
import {Carousel} from "./ui/Carousel.tsx";

const Projects = () => {
  return (
    <div className="h-fit flex flex-col mx-auto w-5/6" id="projects">
      <div className="mt-20">
        <h1 className="text-4xl lg:text-6xl font-bold text-white">
          Projects
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row w-full mt-10 gap-10">
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center order-last lg:order-none">
          <h2 className="text-3xl lg:text-4xl font-bold text-[#38bdf8]">
            Travbase
          </h2>
          <p className="text-xl mt-5">
            Travbase is a service which provides tourists
            with different routes depending on their search.
            Just specify what you want and
            our algorithms will find the best suitable options for you.
          </p>
          <p className="text-xl mt-5">
            For this app I used MERN stack and other technologies.
            It is not just pet-project but my unique idea.
            I`ve spent a lot of time making this product perfect as much a possible.
            So, it may be considered as a project with commercial potential.
          </p>
          <div className="text-xl mt-5 flex gap-3 flex-wrap font-bold">
            <div className="badge p-5">React</div>
            <div className="badge p-5">Redux</div>
            <div className="badge p-5">Redux Thunk</div>
            <div className="badge p-5">NodeJS</div>
            <div className="badge p-5">Express</div>
            <div className="badge p-5">Axios</div>
            <div className="badge p-5">MongoDB</div>
            <div className="badge p-5">Tailwind</div>
            <div className="badge p-5">DaisyUI</div>
            <div className="badge p-5">Other libraries</div>
          </div>
          <div className="mt-10 flex gap-10">
            <a href="https://travbase.net/"
               target="_blank"
               className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8] capitalize text-2xl w-full lg:w-fit px-10">
              Visit Website
            </a>
          </div>
        </div>
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center items-end">
          <img
            className="rounded-xl object-cover"
            src="https://lh6.googleusercontent.com/9_rOnWRb5o2VZdOLOjnlfg2g037jIml98vT61cRPXBzZjX8UWHhiM_HVgxNwtBWZ4b4=w2400"
            alt="travbase logo"/>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full mt-20 gap-10">
        <div
          className="flex flex-col text-white h-full lg:w-1/2 justify-center items-end order-last lg:order-none">
          <h2 className="text-3xl lg:text-4xl font-bold text-[#38bdf8]">
            Cat App
          </h2>
          <p className="text-xl mt-5 text-end">
            API based social network app with cat png/jpeg/gif pictures.
          </p>
          <p className="text-xl mt-5 text-end">
            App includes these features:
            Sharing pictures, Voting for pictures, Saving to Favourite,
            Liking\Disliking, Searching for a breed and more.
          </p>
          <p className="text-xl mt-5 text-end">
            Light/Dark theme switcher and responsive design for all screen
            resolutions.
          </p>
          <div className="text-xl mt-5 flex gap-3 flex-wrap font-bold justify-end">
            <div className="badge p-5 ">React</div>
            <div className="badge p-5">NodeJS</div>
            <div className="badge p-5">Axios</div>
            <div className="badge p-5">Other libraries</div>
          </div>
          <div className="mt-10 flex gap-5 lg:gap-10 flex-wrap justify-end w-full lg:w-fit">
            <a href="https://cat-app-art.netlify.app/"
               target="_blank"
               className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8] capitalize text-2xl w-full lg:w-fit px-10 ">
              Visit Website
            </a>
            <a href="https://github.com/ArturOnop/cat-app"
               target="_blank"
               className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8] capitalize text-2xl w-full lg:w-fit px-10">
              GitHub
            </a>
          </div>
        </div>
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center items-start order-first">
          <img
            className="rounded-xl object-cover"
            src="https://lh3.googleusercontent.com/H5IY8dBjJNVDWqeIcXglToTHKA7BJk1e70bbffmaXhwylNndq7De4lUihZEORY_MUqM=w2400"
            alt="cat app home page"/>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full mt-20 gap-10">
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center order-last lg:order-none">
          <h2 className="text-3xl lg:text-4xl font-bold text-[#38bdf8]">
            Weather App
          </h2>
          <p className="text-xl mt-5">
            Weather App is a mobile application which provides you with such data as current weather, current condition
            details (wind, humidity, UV, pressure), hourly forecast for 24h, daily forecast for 7d,
            today's sunset and sunrise and hourly details (wind, humidity, UV, pressure) for 24h.
          </p>
          <div className="text-xl mt-5 flex gap-3 flex-wrap font-bold">
            <div className="badge p-5">React Native</div>
            <div className="badge p-5">Expo</div>
            <div className="badge p-5">Redux Toolkit</div>
            <div className="badge p-5">Axios</div>
            <div className="badge p-5">Tailwind</div>
            <div className="badge p-5">Weather API</div>
            <div className="badge p-5">Geocoding API</div>
            <div className="badge p-5">Other libraries</div>
          </div>
          <div className="mt-10 flex gap-10">
            <a href="https://github.com/ArturOnop/weather-application"
               target="_blank"
               className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8] capitalize text-2xl w-full lg:w-fit px-10">
              GitHub
            </a>
          </div>
        </div>
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center items-end">
          <Carousel items={[
            "/images/1.1.jpg",
            "/images/1.2.jpg",
            "/images/2.1.jpg",
            "/images/2.2.jpg",
            "/images/3.1.jpg",
            "/images/3.2.jpg",
          ]}
                    vertical
                    direction="right"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full mt-20 gap-10">
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center order-last lg:order-none">
          <h2 className="text-3xl lg:text-4xl font-bold text-[#38bdf8] text-end">
            Superhero App
          </h2>
          <p className="text-xl mt-5 text-end">
            Full-stack advanced CRUD app using MERN stack.
          </p>
          <p className="text-xl mt-5 text-end">
            Also, app includes responsive design and Jest testing.
          </p>
          <div className="text-xl mt-5 flex gap-3 flex-wrap font-bold justify-end">
            <div className="badge p-5">React</div>
            <div className="badge p-5">React Router</div>
            <div className="badge p-5">Redux</div>
            <div className="badge p-5">Redux Thunk</div>
            <div className="badge p-5">NodeJS</div>
            <div className="badge p-5">Express</div>
            <div className="badge p-5">Axios</div>
            <div className="badge p-5">Jest</div>
            <div className="badge p-5">MongoDB</div>
            <div className="badge p-5">Filepond</div>
            <div className="badge p-5">Other libraries</div>
          </div>
          <div className="mt-10 flex gap-10 justify-end">
            <a href="https://github.com/ArturOnop/superheroes-app"
               target="_blank"
               className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8] capitalize text-2xl w-full lg:w-fit px-10">
              GitHub
            </a>
          </div>
        </div>
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center items-start order-first">
          <img
            className="rounded-xl object-cover"
            src="https://lh4.googleusercontent.com/mqYhhJioRRCUyZHZ-Hrz7MXTjZx4BcDBDuE00Wo86hIzLETNOf7Rs5Yommt-SgP-AJY=w2400"
            alt="cat app home page"/>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full mt-20 gap-10">
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center order-last lg:order-none">
          <h2 className="text-3xl lg:text-4xl font-bold text-[#38bdf8]">
            Finance app
          </h2>
          <p className="text-xl mt-5">
            Finance app provides you with different features like possibility to monitor tickers, specify
            interval of update, adding and removing tickers to watch list and more
          </p>
          <p className="text-xl mt-5">
            Project consists of frontend and backend. React components are 100% tested.
          </p>
          <div className="text-xl mt-5 flex gap-3 flex-wrap font-bold">
            <div className="badge p-5">React</div>
            <div className="badge p-5">React Router</div>
            <div className="badge p-5">Redux</div>
            <div className="badge p-5">Redux Thunk</div>
            <div className="badge p-5">NodeJS</div>
            <div className="badge p-5">Express</div>
            <div className="badge p-5">Socket IO</div>
            <div className="badge p-5">React Testing</div>
            <div className="badge p-5">Tailwind</div>
            <div className="badge p-5">DaisyUI</div>
            <div className="badge p-5">Other libraries</div>
          </div>
          <div className="mt-10 flex gap-10">
            <a href="https://github.com/ArturOnop/finance-app"
               target="_blank"
               className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8] capitalize text-2xl w-full lg:w-fit px-10">
              GitHub
            </a>
          </div>
        </div>
        <div className="flex flex-col text-white h-full lg:w-1/2 justify-center items-end">
          <img
            className="rounded-xl object-cover"
            src="https://lh4.googleusercontent.com/xKMVU0kvemGq46xc5RP0HLjb2dpTlHmJZ5wpWVnNHCmVZLp5GwO11jXuN-uZS6MXbKE=w2400"
            alt="finance app logo"/>
        </div>
      </div>
      <div className="flex flex-col w-full lg:mt-20 items-center">
        <h2 className="text-4xl font-bold text-[#38bdf8] text-center block mt-10 lg:mt-5">More projects</h2>
        <Carousel items={[
          "https://lh3.googleusercontent.com/pw/AP1GczOIBZmU64Is-iy6h2JhMs-fJcTKil0vuFDWFwQ05rKtIy66jkRu6Z6i8bj4BwAj89ffk_GzW9W5cTSbHr_HYc93GnGBRaFR4hxRg9AgMP26UQ-Q1nM=w2400",
          "https://lh6.googleusercontent.com/GU-BsM6VtCpO3bzHcG1NJl73e-EBZml1BApz6mhZkmhznqAaIIUaB3devHeTOHqoY1k=w2400",
          "https://lh3.googleusercontent.com/pw/AP1GczNROgACS2Ip8L0JwVv6xyYP-xm7hxSrbgYwxJ9ttqNoFW2U2eNWorIMLeQW6-TcosJI9qN6itXEWJIkLN5bEyw2D59Bvb-nOvDsSwwjhrq_965gvDQ=w2400",
          "https://lh4.googleusercontent.com/fa43oY1FLs3_4iOKk7J4KsGDrYvRN0WGx0qTn6GiyzQBAjHY4EVW-QuuI8OpW1hUL3U=w2400",
          "https://lh3.googleusercontent.com/pw/AP1GczMdmStVtrCM9ftN6AhPbmSHtpqwjtKfpteWv2WjVOKPaJ4xTWIcE1701p8c_WJ85sWl6qw_othM78EIXbKxjI3G4ME5CxGZOypjTrbT_0kkL-UQfOs=w2400"
        ]}
                  direction="right"
        />
        <a href="https://github.com/ArturOnop"
           target="_blank"
           className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8] normal-case text-2xl lg:text-4xl w-full lg:w-2/3 h-14 mt-10">
          See more on GitHub
        </a>
      </div>
    </div>
  );
};

export default Projects;