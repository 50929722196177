const SidePanel = () => {
  return (
    <div className="absolute top-0 right-5 h-screen z-50 hidden lg:block">
      <div className="bg-transparent h-full flex flex-col justify-center gap-10">
        <a href="https://www.linkedin.com/in/artur-onopriichuk"
           target="_blank"
           className="tooltip"
           data-tip="LinkedIn">
          <div className="h-10 w-10 bg-white flex justify-center items-center rounded-lg">
            <img src="images/linkedin.svg" className="w-5 h-5"/>
          </div>
        </a>
        <a href="https://djinni.co/q/195b19df03/"
           target="_blank"
           className="tooltip"
           data-tip="Djinni">
          <div className="h-10 w-10 bg-white flex justify-center items-center rounded-lg">
            <img src="images/djinni.svg" className="w-5 h-5"/>
          </div>
        </a>
        <a href="https://t.me/art_i_i"
           target="_blank"
           className="tooltip"
           data-tip="Telegram">
          <div className="h-10 w-10 bg-white flex justify-center items-center rounded-lg">
            <img src="images/telegram.svg" className="w-5 h-5"/>
          </div>
        </a>
      </div>
    </div>
  );
};

export default SidePanel;