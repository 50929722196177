const AboutMe = () => {
  return (
    <div className="min-h-screen flex flex-col lg:flex-row items-center mt-20 lg:mt-0 w-5/6 mx-auto" id="about-me">
      <div className="flex flex-col text-white h-full lg:w-1/2">
        <h1 className="text-4xl lg:text-6xl font-bold">
          Me, Myself & I
        </h1>
        <p className="text-xl mt-5">
          My name is Artur and I am from <span className="ukraine">Ukraine</span>.
          I love web development and been practicing it for 3 years.
        </p>
        <p className="text-xl mt-5">
          As a highly motivated and enthusiastic Software Engineer and Computer Science graduate,
          I possess a strong foundation in programming concepts and
          1.5 years of commercial experience.
        </p>
        <p className="text-xl mt-5">
          I am passionate to take part in delivering high-quality software solutions, gain & share valuable experience
          and work on ambitious projects with positive people within your company.
        </p>
        <p className="text-xl mt-5">
          My favourite tech stack is MERN and I love travelling :)
        </p>
      </div>
      <div className="flex flex-col text-white h-full lg:w-1/2 items-center my-10">
        <img className="mask border border-black rounded-xl h-52 w-52 lg:h-72 lg:w-72"
             src="https://lh6.googleusercontent.com/wBmGVCmd_qW3r1R7oFJqEY47rph-idCB4NbuSulkz6AmdYXm7b2YDMxfnLbM2NgJFqU=w2400"
             alt="me"/>
      </div>
    </div>
  );
};

export default AboutMe;