import Home from "./components/Home.tsx";
import Navbar from "./components/Navbar.tsx";
import AboutMe from "./components/AboutMe.tsx";
import MySkills from "./components/MySkills.tsx";
import Projects from "./components/Projects.tsx";
import Contact from "./components/Contact.tsx";
import SidePanel from "./components/SidePanel.tsx";

const App = () => {
  return (
    <>
      <Navbar/>
      <SidePanel/>
      <div className="container bg-[#0c0e0f]">
        <Home/>
        <AboutMe/>
        <MySkills/>
        <Projects/>
        <Contact/>
      </div>
    </>
  );
}

export default App;
