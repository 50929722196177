const MySkills = () => {
  return (
    <div className="min-h-screen flex flex-col lg:flex-row items-center gap-y-10 mt-20 lg:mt-0 w-5/6 mx-auto"
         id="my-skills">
      <div className="flex flex-col text-white h-full lg:w-1/2 justify-center">
        <h1 className="text-4xl lg:text-6xl font-bold text-white">
          My Skills and Experience
        </h1>
        <p className="text-xl mt-5">
          My latest commercial experience includes working as a Software Engineer at Amdocs, developing and maintaining
          features within a microservices architecture using Java Spring and Kubernetes. Also, taking part in building
          dynamic, responsive UIs with React and Redux, while optimizing software delivery through Jenkins and Docker.
        </p>
        <p className="text-xl mt-5">
          At Belkins, I developed responsive UI features with JavaScript, React and Redux, optimizing load time by 15%
          and boosting user engagement. I collaborated closely with cross-functional teams in Agile workflows.
        </p>
        <p className="text-xl mt-5">
          Previously, as a Full Stack Developer Intern at Unicorn Czechia, I created PWAs with React, Node.js and
          MongoDB. During my GR8 Tech internship, I contributed to front-end projects using React and Redux in a team
          environment.
        </p>
        <p className="text-xl mt-5">
          Furthermore, I do have several pet
          <a
            className="font-bold text-[#38bdf8]"
            href="#projects">
            &nbsp;projects
          </a>.
        </p>
        <div className="text-xl mt-5">
          <p>I have finished several courses in the past as well:</p>
          <ul className="flex flex-col gap-1">
            <li className="hover:text-[#38bdf8]"><a
              href="https://www.coursera.org/account/accomplishments/verify/8CCSG2NUCW3D"
              target="_blank">
              2022 Coursera, Front-End Web Development with React
            </a></li>
            <li className="hover:text-[#38bdf8]"><a
              href="https://www.freecodecamp.org/certification/offtee/responsive-web-design"
              target="_blank">
              2021 FreeCodeCamp, Responsive Web Design
            </a></li>
            <li className="hover:text-[#38bdf8]"><a
              href="https://www.freecodecamp.org/certification/offtee/javascript-algorithms-and-data-structures"
              target="_blank">
              2021 FreeCodeCamp, JS Algorithms and Data Structures
            </a></li>
          </ul>
        </div>
        <div className="text-2xl mt-5 font-bold text-[#38bdf8]">
          <a href="https://drive.google.com/file/d/1fT4o2HVOUP83CN5VcLG91oK9tx2YsMfu/view?usp=sharing"
             target="_blank">
            My Resume
          </a>
        </div>
      </div>
      <div
        className="flex flex-col text-white h-full w-full lg:w-1/2 justify-center
                text-2xl font-bold text-white items-baseline lg:items-center gap-5">
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">JavaScript</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="95"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">TypeScript</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="95" max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">Java</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="90"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">React</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="85"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">Redux</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="85"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">SQL/NoSQL</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="80"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">Node.js</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="75"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">Kubernetes & Docker</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="73"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">CI/CD</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="73" max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">ReactNative</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="70" max="100"/>
        </div>
      </div>
    </div>
  );
};

export default MySkills;